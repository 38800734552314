<template>
  <div class="home">
    <div class="container">
      <div class="title">
        <div class="white-title-part">
          (Code)_
        </div>
        <div class="accent-title-part">
          name.
        </div>
      </div>
      <div class="start-button-container">
        <Button @click="onNewGameButtonClick">
          Nouvelle partie
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'

export default {
  components: {
    Button
  },
  methods: {
    onNewGameButtonClick: function () {
      this.$router.push({ name: 'Generator' })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  align-items: center;
  display: grid;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  .title {
    font-size: 50px;
    margin-bottom: 150px;

    .white-title-part {
      color: #F2F2F2;
      padding-right: 30px;
    }

    .accent-title-part {
      color: #6EFFBA;
      padding-left: 30px;
    }
  }
}
</style>
