<template>
  <div class="color" :class="colorName">

  </div>
</template>

<script>
export default {
  props: {
    colorName: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.color {
  border-radius: 5px;
  display: inline-block;
  width: 35px;
  height: 35px;

  &.neutral {
    background-color: #E9E5CE;
  }

  &.blue {
    background-color: #0057FF;
  }

  &.red {
    background-color: #DD0000;
  }

  &.gray {
    background-color: #707070;
  }
}
</style>
