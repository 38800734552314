<template>
  <div class="generator">
    <div class="sections-container">
      <div class="controls-section">
        <Button class="left-column" @click="onHomeButtonClick">Home</Button>
        <Button class="right-column" @click="shuffle">Fresh</Button>
        <div class="left-column team-title">
          @Équipe.
        </div>
        <div class="right-column team-color">
          <Color :colorName="startingTeamColor"></Color>
        </div>
      </div>
      <div class="positions-grid-section">
        <div class="positions-grid-container">
          <div class="positions-grid">
            <Color v-for="(color, index) in gridData"
                  :key="index"
                  :colorName="color">
            </Color>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="title">
        <div class="white-title-part">
          (Code)_
        </div>
        <div class="accent-title-part">
          name.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import Color from '@/components/Color'

const R = require('ramda')

export default {
  components: {
    Button,
    Color
  },
  data () {
    return {
      startingTeamColor: null,
      gridData: []
    }
  },
  created () {
    this.shuffle()
  },
  methods: {
    onHomeButtonClick: function () {
      this.$router.push({ name: 'Home' })
    },
    shuffle: function (shuffleCounter = 30) {
      this.generateNewGrid()

      if (shuffleCounter > 0) {
        setTimeout(() => {
          this.shuffle(shuffleCounter - 1)
        }, 20)
      }
    },
    generateNewGrid: function () {
      const possibleTeamColors = ['red', 'blue']

      const startingTeamColorIndex = Math.floor(Math.random() * 2)

      const startingTeamColor = R.nth(startingTeamColorIndex)(possibleTeamColors)
      const nonStartingTeamColor = R.pipe(R.reverse, R.nth(startingTeamColorIndex))(possibleTeamColors)

      const gridData = R.pipe(
        R.append(R.repeat(startingTeamColor, 9)),
        R.append(R.repeat(nonStartingTeamColor, 8)),
        R.append(R.repeat('neutral', 7)),
        R.append('gray'),
        R.flatten,
        this.shuffleArray
      )([])

      this.startingTeamColor = startingTeamColor
      this.gridData = gridData
    },
    shuffleArray: function (startingArray) {
      return this.recursiveShuffleArray(startingArray)
    },
    recursiveShuffleArray: function (startingArray, shuffledArray = []) {
      const startingArrayLength = R.length(startingArray)

      if (startingArrayLength > 0) {
        const randomStartingArrayIndex = Math.floor(Math.random() * startingArrayLength)

        return this.recursiveShuffleArray(
          R.remove(randomStartingArrayIndex, 1, startingArray),
          R.append(R.nth(randomStartingArrayIndex, startingArray), shuffledArray)
        )
      } else {
        return shuffledArray
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.generator {
  .sections-container {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;

    @include breakpoint(portrait) {
      grid-template-columns: 1fr;
    }

    .controls-section {
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 60px;
      row-gap: 30px;

      .left-column {
        justify-self: start;
      }

      .right-column {
        justify-self: end;
      }

      .team-title {
        color: #6EFFBA;
        font-size: 30px;
      }
    }

    .positions-grid-section {
      background-color: #323232;
      border-top: 2px solid #6EFFBA;
      border-bottom: 2px solid #6EFFBA;
      padding: 30px 0;

      @include breakpoint(portrait) {
        margin: 10px -30px;
      }

      .positions-grid-container {
        display: inline-block;

        .positions-grid {
          column-gap: 10px;
          display: grid;
          grid-template-columns: auto auto auto auto auto;
          row-gap: 10px;
        }
      }
    }
  }

  .footer {
    font-size: 14px;
    text-align: right;

    @include breakpoint(portrait) {
      margin-right: -15px;
    }

    .white-title-part {
      color: #F2F2F2;
      padding-right: 5px;
    }

    .accent-title-part {
      color: #6EFFBA;
      padding-left: 5px;
    }
  }
}
</style>
