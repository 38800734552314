<template>
  <button class="button" type="button" @click="onButtonClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  methods: {
    onButtonClick: function () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  border: none;
  border-radius: 5px;
  color: #F9FAFB;
  background-color: #BF03FF;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  padding: 10px 16px;

  &:hover {
    cursor: pointer;
  }
}
</style>
